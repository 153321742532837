<template>
  <v-app v-if="currentUser">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <keep-alive>
        <NavigationMenu v-model="drawer" />
      </keep-alive>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>CWKO</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row align="center" justify="center">
          <slot />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationMenu from '@/layouts/components/NavigationMenu.vue';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import { clearTokens, onLogout } from '@/plugins/apollo';

export default {
  name: 'FullLayout',
  components: { NavigationMenu },
  // created() {
  //   this.$vuetify.theme.dark = false
  // },
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    logout() {
      onLogout(this.$apollo.getClient());
      this.$router.push({ name: 'login' });
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update: (data) => {
        if (!data.me) {
          this.$router.push({ name: 'login' });
        }
        return data.me;
      },
      error: () => {
        clearTokens();
        window.location = '/';
      },
    },
  },
};
</script>
