import { ApolloLink } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import Cookies from 'js-cookie';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createUploadLink } from 'apollo-upload-client';

import apolloRefreshTokenLink from '@/plugins/apolloRefreshTokenLink'; // eslint-disable-line import/no-cycle
import customFetch, { fetchCookieDomain } from '@/helpers/dynamicFetch';
import introspectionQueryResultData from './introspectionQueryResultData.json';
// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const AUTH_COOKIE = 'BEARER';
const REFRESH_TOKEN = 'apollo-refresh-token';

// =========================
// apollo links setup below
// =========================
export const httpLink = createUploadLink({ fetch: customFetch });

const link = ApolloLink.from([apolloRefreshTokenLink, httpLink]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

// =========================
// apollo client config
// =========================
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  // httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link,

  // Override default cache
  cache,

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });

  return apolloProvider;
}

export async function saveToken(token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  Cookies.set(AUTH_COOKIE, token, { domain: await fetchCookieDomain() });
}

export function saveRefreshToken(token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(REFRESH_TOKEN, token);
  }
}

export function getRefreshToken() {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(REFRESH_TOKEN);
  }
  return null;
}
export function getToken() {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN);
  }
  return null;
}

// Manually call this when user log in
export async function onLogin(apolloClient, token, refreshToken) {
  saveToken(token);
  saveRefreshToken(refreshToken);

  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

export const isLoggedIn = () => localStorage.getItem(AUTH_TOKEN) !== null;

export async function clearTokens() {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
  Cookies.remove(AUTH_COOKIE, { domain: await fetchCookieDomain() });
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  clearTokens();
  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
