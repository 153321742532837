<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <slot />
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// clear layout for login page etc
export default {
  name: 'SimpleWideLayout',
};
</script>
