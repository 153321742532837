import Vue from 'vue';
import VueApollo from 'vue-apollo';
// import pl from 'moment/locale/pl';
import VueMoment from 'vue-moment';
import moment from 'moment';
import App from '@/App.vue';
import router from '@/router';
import { createProvider } from '@/plugins/apollo';
import vuetify from '@/plugins/vuetify';
// @ts-ignore
import '@/plugins/vueEasyLightbox';
import '@/plugins/filters';

moment.locale('pl');

Vue.use(VueApollo);
Vue.use(VueMoment, { moment });
Vue.config.productionTip = false;
new Vue({
  router,
  apolloProvider: createProvider(),
  // @ts-ignore
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
