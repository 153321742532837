import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
// @ts-ignore
import EXCHANGE_REFRESH_TOKEN_QUERY from '@/graphql/security/mutation/ExchangeRefreshToken.gql';
import { getRefreshToken, httpLink } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle

// Http endpoint
export default async () => {
  const { apolloClient: client } = createApolloClient({
    link: httpLink,
    getAuth: () => null, // dont send any token for refresh action
  });
  const result = await client.mutate({
    variables: { refreshToken: getRefreshToken() },
    mutation: EXCHANGE_REFRESH_TOKEN_QUERY,
  });

  return Promise.resolve({
    accessToken: result.data.exchangeRefreshToken.token.value,
    refreshToken: result.data.exchangeRefreshToken.refreshToken.value,
  });
};
