import Vue from 'vue';
import VueRouter from 'vue-router';
import { isLoggedIn } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle

Vue.use(VueRouter);

const requireAuth = (to, from, next) => {
  if (!isLoggedIn()) {
    next('login');
    return;
  }

  next();
};

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'SimpleLayout' }, // maps to "SimpleLayout.vue"
    component: () => import(/* webpackChunkName: "general" */ '@/pages/security/LoginPage.vue'),
  },
  {
    path: '/legitymacja/:id',
    name: 'users/id-card',
    meta: { layout: 'SimpleLayout' }, // maps to "SimpleLayout.vue"
    props: (router) => ({
      id: router.params.id,
    }),
    component: () => import(/* webpackChunkName: "general" */ '@/pages/user/IdCardPage.vue'),
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/HomePage.vue'),
  },
  {
    path: '/users',
    name: 'users',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/user/UsersListPage.vue'),
  },
  {
    path: '/congress-dates',
    name: 'congress-dates',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "general" */ '@/pages/congressDate/CongressDateListPage.vue'),
  },
  {
    path: '/congress-dates/:id/display',
    name: 'congress-dates/display',
    beforeEnter: requireAuth,
    props: (router) => ({
      id: router.params.id,
    }),
    component: () => import(/* webpackChunkName: "general" */ '@/pages/congressDate/CongressDateDisplayPage.vue'),
  },
  {
    path: '/congress-dates/:id/print',
    name: 'congress-dates/print',
    beforeEnter: requireAuth,
    props: (router) => ({
      id: router.params.id,
    }),
    meta: { layout: 'RawLayout' },
    component: () => import(/* webpackChunkName: "general" */ '@/pages/congressDate/CongressDatePrintDiplomasPage.vue'),
  },
  {
    path: '/users/:id/display',
    name: 'users/display',
    beforeEnter: requireAuth,
    props: (router) => ({
      id: router.params.id,
    }),
    component: () => import(/* webpackChunkName: "general" */ '@/pages/user/UserDetailsPage.vue'),
  },
  {
    path: '/exams',
    name: 'exams',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "exam" */ '@/pages/exam/ExamsListPage.vue'),
  },
  {
    path: '/exams/check',
    name: 'exams/check',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "exam" */ '@/pages/exam/ExamCheckerPage.vue'),
  },
  {
    path: '/exams/:id',
    name: 'exams/display',
    beforeEnter: requireAuth,
    props: (router) => ({
      id: router.params.id,
    }),
    component: () => import(/* webpackChunkName: "exam" */ '@/pages/exam/ExamDetailsPage.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "general" */ '@/pages/404.vue'),
  },
  {
    path: '/rozwiaz-test/:token',
    name: 'resolve-test',
    meta: { layout: 'SimpleWideLayout' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/test-platform/ResolveTestView.vue'),
    props: (router) => {
      return {
        token: router.params.token,
      };
    },
  },
  {
    path: '/zapomnialem-haslo',
    name: 'forgot-password',
    meta: { layout: 'SimpleWideLayout' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "security" */ '@/pages/security/ForgotPasswordPage.vue'),
    props: (router) => {
      return {
        token: router.params.token,
      };
    },
  },
  {
    path: '/finalize-registration/:token',
    name: 'finalize-registration',
    meta: { layout: 'SimpleLayout' }, // maps to "SimpleLayout.vue"
    props: (router) => ({
      token: router.params.token,
    }),
    component: () => import(/* webpackChunkName: "security" */ '@/pages/security/FinalizeRegistrationPage.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    meta: { layout: 'SimpleLayout' }, // maps to "SimpleLayout.vue"
    props: (router) => ({
      token: router.params.token,
    }),
    component: () => import(/* webpackChunkName: "security" */ '@/pages/security/FinalizePasswordResetPage.vue'),
  },
];

const routerInstance = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default routerInstance;
