import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import pl from 'vuetify/es5/locale/pl';

Vue.use(Vuetify);

// @ts-ignore
Vue.component({
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'pl';
    },
  },
});
// import colors from 'vuetify/lib/util/colors';
export default new Vuetify({
  lang: {
    locales: { pl },
    current: 'pl',
  },
  theme: {
    themes: {
      light: {
        primary: '#374a5c', // colors.red.darken1, // #E53935 // 374a5c
        secondary: '#993265', // colors.red.lighten4, // #FFCDD2 // 993265
        accent: '#53575C', // colors.indigo.base, // #3F51B5
      },
    },
  },
});
