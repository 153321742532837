<template>
  <!--  item whith childrens -->
  <v-list-group
    v-if="item.children"
    :key="item.text"
    :value="item.text"
    v-model="item.model"
    :prepend-icon="item.model ? item.icon : typeof item['icon-alt'] != 'undefined' ? item['icon-alt'] : item.icon"
    append-icon=""
    color="light-green"
  >
    <template v-slot:activator>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <!-- ------------------ -->
    <router-link v-slot="{ route }" v-for="(child, i) in item.children" :key="`${child}-${i}`" :to="child.route">
      <v-list-item :to="route" link color="light-green lighten-2">
        <v-list-item-title>{{ child.text }}</v-list-item-title>
        <v-list-item-action v-if="child.icon">
          <v-icon>{{ child.icon }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </router-link>
  </v-list-group>
  <!-- below this line - single item without no children -->
  <router-link :to="item.route" v-slot="{ route }" v-else>
    <v-list-item :key="item.text" :value="item.text" link :to="route" color="light-green">
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </router-link>
  <!--  </router-link>-->
</template>
<script>
export default {
  name: 'NavigationItem',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  methods: {
    itemClasses() {
      // item
      const classes = [];
      // if(item.children){
      //   classes.push('v-list-group--active');
      // }
      // if (item.route && item.route.name == this.$route.name) {
      //   classes.push('v-list-item--active');
      // }
      return classes;
    },
  },
};
</script>
