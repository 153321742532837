<template>
  <v-navigation-drawer v-model="drawer" dark app>
    <v-sheet class="pa-4" dark>
      <div v-if="currentUser">{{ currentUser.userName }}</div>
    </v-sheet>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item-group color="light-green">
        <NavigationItem :key="i" :item="item" v-for="(item, i) in items" />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import NavigationItem from '@/layouts/components/NavigationItem.vue';
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';

export default {
  components: { NavigationItem },
  props: {
    value: Boolean,
  },
  // methods: {
  //   clicked(item) {
  //     if (typeof item.route == "undefined") {
  //       return;
  //     }
  //     this.$router.push(item.route);
  //   }
  // },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set() {},
    },
    items() {
      const baseMenu = {
        home: {
          icon: 'mdi-home-outline',
          text: 'Stona główna',
          // TODO:FIX: children test
          // children: [
          // {
          //   icon: 'mdi-calendar',
          //   text: 'test',
          //   children: [{ name: 'test' }],
          //   route: { name: 'home' },
          // },
          // ],
          route: { name: 'home' },
        },
        users: {
          icon: 'mdi-user',
          text: 'Użytkownicy',
          route: { name: 'users' },
        },
        congressDates: {
          icon: 'mdi-user',
          text: 'Kursy',
          route: { name: 'congress-dates' },
        },
        exam: {
          icon: 'mdi-user',
          text: 'Egzaminy',
          route: { name: 'exams' },
        },
      };

      Object.keys(baseMenu).forEach((key) => {
        if (baseMenu[key].children && baseMenu[key].children.length === 0) {
          delete baseMenu[key];
        }
      });

      if (!this.currentUser?.acl_list_exams) {
        delete baseMenu.exam;
      }
      return baseMenu;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update: (data) => data.me,
    },
  },
  data() {
    return {
      user: null,
    };
  },
};
</script>
