import Vue from 'vue';
import moment from 'moment';

Vue.filter('date', (value) => moment(value).format('DD-MM-YYYY'));
// @ts-ignore
Vue.filter('dateFrom', (value) => moment(value).from());
Vue.filter('dateTime', (value) => moment(value).format('DD-MM-YYYY, HH:mm'));
Vue.filter('orDash', (value) => value || '-');
Vue.filter('orEmpty', (value) => value || 'brak');
Vue.filter('money', (value) => {
  return `${value.amount / 100} ${value.currency.code}`;
});
Vue.filter('orNoInfo', (value) => value || 'Brak informacji');
