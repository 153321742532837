import { onError } from 'apollo-link-error';
import { fromPromise } from 'apollo-link';
import getNewToken from '@/plugins/apolloGetTokenHelper'; // eslint-disable-line import/no-cycle
import routerInstance from '@/router'; // eslint-disable-line import/no-cycle
import { clearTokens, saveRefreshToken, saveToken } from '@/plugins/apollo'; // eslint-disable-line import/no-cycle

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export default onError(({ networkError, operation, forward }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if ((networkError as any).statusCode !== 401) {
    // TODO apollo-link-retry
    return; // ignore all but 401 codes
  }

  let forward$;
  if (!isRefreshing) {
    isRefreshing = true;
    forward$ = fromPromise(
      getNewToken()
        .then(({ accessToken, refreshToken }) => {
          // console.log("%c %s", "color:red; background-color: blue;", accessToken);
          saveToken(accessToken);
          saveRefreshToken(refreshToken);
          // Store the new tokens for your auth link
          resolvePendingRequests();
          return accessToken;
        })
        .catch(() => {
          pendingRequests = [];
          clearTokens();
          routerInstance.push({ name: 'login' });
          // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
        })
        .finally(() => {
          isRefreshing = false;
        }),
    )
      .filter((value) => Boolean(value))
      .flatMap((accessToken) => {
        const oldHeaders = operation.getContext().headers;
        // modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // retry the request, returning the new observable
        return forward(operation);
      });
  } else {
    // Will only emit once the Promise is resolved
    forward$ = fromPromise(
      new Promise((resolve) => {
        // @ts-ignore
        pendingRequests.push(() => resolve());
      }),
    );
  }

  return forward$.flatMap(() => forward(operation)); // eslint-disable-line consistent-return
});
