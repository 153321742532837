<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import FullLayout from '@/layouts/FullLayout.vue';
import SimpleLayout from '@/layouts/SimpleLayout.vue';
import SimpleWideLayout from '@/layouts/SimpleWideLayout.vue';
import RawLayout from '@/layouts/RawLayout.vue';

const DEFAULT_LAYOUT = 'FullLayout';

export default {
  name: 'App',
  components: { FullLayout, SimpleLayout, SimpleWideLayout, RawLayout },
  computed: {
    layout() {
      return this.$route.meta.layout || DEFAULT_LAYOUT;
    },
  },
};
</script>
